<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16 px-1"
  >
    <!--------------------------------------STEPPER-------------------------->
    <v-row class="mb-0 d-flex justify-center align-center">
      <v-col
        cols="12"
        sm="9"
        md="9"
        lg="9"
        class="pb-0 mb-0"
      >
        <v-card class="white px-auto pb-0 mb-0">
          <v-stepper>
            <v-stepper-header>
              <v-stepper-step
                step="1"
                complete
                complete-icon="mdi-home-circle"
              >
                RENTAL INFO
              </v-stepper-step>

              <v-divider></v-divider>

              <!-- <v-stepper-step
                step="2"
                complete
                complete-icon="mdi-home-circle"
              >
                CHOOSE PLAN
              </v-stepper-step> -->

              <v-divider></v-divider>

              <v-stepper-step
                step="2"
                complete
                complete-icon="mdi-home-circle"
              >
                PROPERTY INFO
              </v-stepper-step>

              <v-divider></v-divider>
              <v-stepper-step
                step="3"
                complete
                complete-icon="mdi-home-circle"
              >
                COVERAGE
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                step="4"
                complete
                complete-icon="mdi-home-circle"
              >
                PURCHASE PLAN
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="5">
                YOUR POLICY
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
    <!------------------------------------------------FORM---------------------------------->
    <v-row class="d-flex justify-center">
      <v-col
        cols="12"
        sm="9"
        md="9"
        lg="9"
        class="mt-0 pt-1"
      >
        <v-card class="mt-0 pb-16">
          <validation-observer
            ref="observer"
            v-slot="{ invalid }"
          >
            <v-form
              ref="form"
              class="font-weight-bold"
              :class="mobileDevice ? 'px-2' : 'px-8'"
              @submit.prevent="submit"
            >
              <v-row>
                <v-col class="top-row">
                  <div class="title-text text-center mt-2">
                    Pay And Download Policy
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h1 class="primary--text mt-2">
                    Billing Information
                  </h1>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox
                    v-model="billingSameAsProperty"
                    label="Billing Address same as Property Address"
                    @change="updateBillingAddress"
                    @click="
                      clickedFieldGlobal(
                        'purchasePlan updateBillingAdd',
                        112,
                        'Renters Survey v1'
                      )
                    "
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <vuetify-google-autocomplete
                    id="map2"
                    ref="billingAddress"
                    classname="form-control"
                    outlined
                    placeholder="Please type your address"
                    country="us"
                    @placechanged="getBillingAddressData"
                    @click="
                      clickedFieldGlobal(
                        'purchasePlan billingAddAutoComp',
                        113,
                        'Renters Survey v1'
                      )
                    "
                  >
                  </vuetify-google-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Address"
                    rules="required"
                  >
                    <v-text-field
                      v-model="billingAddress"
                      outlined
                      :error-messages="errors"
                      label="Address"
                      @click="
                        clickedFieldGlobal(
                          'purchasePlan billingAddress',
                          114,
                          'Renters Survey v1'
                        )
                      "
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="8"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="City"
                    rules="required"
                  >
                    <v-text-field
                      v-model="billingCity"
                      outlined
                      :error-messages="errors"
                      label="City"
                      @click="
                        clickedFieldGlobal(
                          'purchasePlan billingCity',
                          115,
                          'Renters Survey v1'
                        )
                      "
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="8"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="State"
                    rules="required"
                  >
                    <v-select
                      v-model="billingState"
                      label="State"
                      :items="states"
                      outlined
                      :error-messages="errors"
                      @click="
                        clickedFieldGlobal(
                          'purchasePlan billingState',
                          116,
                          'Renters Survey v1'
                        )
                      "
                    ></v-select>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="8"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Zip Code"
                    :rules="{
                      required: true,
                      regex: /(^\d{5}$)|(^\d{5}-\d{4}$)/
                    }"
                  >
                    <v-text-field
                      v-model="billingZip"
                      outlined
                      :error-messages="errors"
                      label="Zip Code"
                      @click="
                        clickedFieldGlobal(
                          'purchasePlan billingZip',
                          117,
                          'Renters Survey v1'
                        )
                      "
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h1 class="primary--text mt-2">
                    Payment Options:
                  </h1>
                </v-col>
              </v-row>
              <v-row v-if="monthlyDeposit">
                <v-col class="d-flex justify-center">
                  <v-radio-group v-model="paymentOption">
                    <v-radio
                      :label="
                        'Full Payment - 100% paid now: ' + '$' + yearlyPayment
                      "
                      value="Full"
                      @click="
                        clickedFieldGlobal(
                          'purchasePlan Full Payment',
                          118,
                          'Renters Survey v1'
                        )
                      "
                    >
                    </v-radio>
                    <v-radio
                      :label="
                        'Monthly - CC withdrawn monthly: ' +
                          'Deposit Amount: $' +
                          monthlyDeposit +
                          ',' +
                          ' 10 installments of $' +
                          installmentAmount
                      "
                      value="Monthly"
                      @click="
                        clickedFieldGlobal(
                          'purchasePlna Monthly Payment',
                          119,
                          'Renters Survey v1'
                        )
                      "
                    >
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row v-if="!monthlyDeposit">
                <v-col>
                  <p class="text-h4">
                    Full Payment - 100% paid now: $ {{ yearlyPayment }}
                  </p>
                </v-col>
              </v-row>
              <v-credit-card
                :year-digits="4"
                :class="mobileDevice ? 'pa-0' : ''"
                @change="handleCCData"
                @cardChanged="cardChanged"
              ></v-credit-card>
              <v-row>
                <v-col class="d-flex justify-center">
                  <v-btn
                    x-large
                    class="primary white--text font-weight-bold text-center"
                    :disabled="invalid || loading"
                    @click="
                      next();
                      clickedFieldGlobal(
                        'purchasePlan next',
                        120,
                        'Renters Survey v1'
                      );
                    "
                  >
                    Pay Now
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </validation-observer>
          <v-row
            v-if="loading"
            class="mt-12"
          >
            <v-col class="d-flex justify-center">
              <ellipse-progress
                :loading="loading"
                :size="180"
                img-src="src/assets/images/renters-icon.png"
                thickness="5"
                empty-thickness="3"
                line-mode="out 5"
                legend="false"
                animation="rs 700 1000"
                font-size="1.5rem"
                color="#00A1B7"
                empty-color-fill="transparent"
                filename="renters-icon.png"
              >
              </ellipse-progress>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="errorDialog"
      max-width="700"
      persistent
    >
      <v-card class="pa-2">
        <v-card-title class="justify-center font-weight-bold text-h5">
          We are unable to process your request
        </v-card-title>
        <v-card-text class="justify-center text-center body primmary--text">
          <v-row>
            <v-col>
              We’re sorry that we are unable to process your application at this time due to the following error:
            </v-col>
          </v-row>
          <v-row>
            <v-col class="font-italic">
              {{ error }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row
            class="d-flex justify-center"
            no-gutters
          >
            <v-col class="d-flex justify-center">
              <v-btn
                color="primary"
                class="mb-4 mt-4 mr-0 font-weight-bold"
                to="/renters/info"
                @click="clearError"
              >
                Restart Application
              </v-btn>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-btn
                color="primary"
                class="mb-4 mt-4 mr-0 font-weight-bold"
                to="/"
                @click="clearError"
              >
                Return to Home
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

        <v-row
          no-gutters
          class="d-flex justify-center font-weight-bold text-h5"
        >
          <v-col class="d-flex justify-center font-weight-bold text-center">
            Please contact us at 1-(888)-333-3145 for support with your application.
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <error-dialog
      :error-dialog="ccError"
      :error-message="
        'Please ensure credit card information including first and last name is valid.'
      "
      :custom-error-method="true"
      :custom-error-close-button="'Close'"
      @customErrorCloseEvent="ccError = false"
    ></error-dialog>
    <v-dialog
      v-model="formsDialog"
      persistent
      max-width="600"
    >
      <v-card
        min-height="500"
        class="pa-6"
        elevation="0"
      >
        <v-row
          v-if="loading"
          class="mt-12"
        >
          <v-col class="d-flex justify-center">
            <ellipse-progress
              :loading="loading"
              :size="180"
              img-src="src/assets/images/renters-icon.png"
              thickness="5"
              empty-thickness="3"
              line-mode="out 5"
              legend="false"
              animation="rs 700 1000"
              font-size="1.5rem"
              color="#00A1B7"
              empty-color-fill="transparent"
              filename="termlife-icon.png"
            >
            </ellipse-progress>
          </v-col>
        </v-row>
        <div
          v-if="!loading"
          class="px-0"
        >
          <v-row class="justify-center">
            <v-col
              cols="12"
              md="10"
            >
              <v-row>
                <v-col
                  class="text-h3 ma-2 font-weight-bold primary--text text-center pt-6"
                >
                  Please review the following documents and click "I Agree" to
                  continue.
                </v-col>
              </v-row>
              <v-progress-linear
                :active="loading"
                :indeterminate="loading"
                absolute
                color="primary"
              />
              <v-col v-if="!loading">
                <v-row
                  v-for="item in noticeListFiles"
                  :key="item"
                  class="justify-center"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="9"
                    >
                      <v-card-text
                        class="headline-1 ma-4 pa-4 blanketcolor--text"
                      >
                        <a
                          :href="item"
                          target="_blank"
                          @click="
                            clickedFieldGlobal(
                              'purchasePlan viewDocument',
                              121,
                              'Renters Survey v1'
                            )
                          "
                        >
                          View Document</a>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-row>
              </v-col>
              <v-row>
                <v-col class="text-h4 ma-2 font-weight-bold  text-center pt-6">
                  By clicking I agree below, I agree with these Terms and
                  Conditions
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-inline-flex">
                  <v-btn
                    color="secondary"
                    to="/"
                    @click="
                      clearError();
                      clickedFieldGlobal(
                        'purchasePlan cancelBtn',
                        122,
                        'Renters Survey v1'
                      );
                    "
                  >
                    Cancel
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="
                      formsDialog = false;
                      clickedFieldGlobal(
                        'purchasePlan I agree',
                        123,
                        'Renters Survey v1'
                      );
                    "
                  >
                    I Agree
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import numeral from 'numeral'
  import moment from 'moment'
  import EllipseProgress from '../components/EllipseProgress.vue'
  import 'v-credit-card/dist/VCreditCard.css'
  import { STATES } from '@/consts/us-states'
  import VCreditCard from 'v-credit-card'
  export default {
    components: {
      EllipseProgress,
      VCreditCard
    },
    data () {
      return {
        states: STATES,
        billingSameAsProperty: false,
        password: '',
        show: '',
        formsDialog: true,
        ccError: false,
        errorDialog: false,
        paymentOption: 'Full',
        checkbox: true,
        mailingFirstName: '',
        mailingLastName: '',
        mailingEmail: '',
        mailingAddress: '',
        mailingSuite: '',
        mailingCity: '',
        mailingState: '',
        mailingZip: '',
        billingFirstName: '',
        billingLastName: '',
        billingEmail: '',
        billingAddress: '',
        billingSuite: '',
        billingCity: '',
        billingState: '',
        billingZip: '',
        cardholderName: '',
        expMonth: '',
        expYear: '',
        cardNumber: '',
        cvv: '',
        standard: '',
        preferred: '',
        premium: '',
        methodPaymentCd: '',
        ccName: null,
        ccNumber: null,
        ccExp: null,
        ccCvv: null
      }
    },
    computed: {
      propertyAddress () {
        return this.quote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
          .Location[0].Addr[0]
      },
      errorMessageAccount () {
        let message = ''
        message = this.$store.getters.getErrors
        return message
      },
      userpresent () {
        let confirmedUser = false
        console.log(
          'confirmed user ',
          confirmedUser,
          this.$store.getters.getuser
        )
        if (this.$store.getters.getuser !== null) {
          confirmedUser = true
        }
        console.log('confirmed user ', confirmedUser)
        return confirmedUser
      },
      noticeListFiles () {
        let cloudNotices = []
        cloudNotices = this.$store.getters.getRentersPdfs
        console.log('cloudNotices', cloudNotices)
        return cloudNotices
      },
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      error () {
        return this.$store.getters.error
      },
      billAddr () {
        return {
          addrTypeCd: 'BillingAddress',
          addr1: this.billingAddress,
          city: this.billingCity,
          stateProvCd: this.billingState,
          postalCode: this.billingZip
        }
      },
      paymentData () {
        return {
          paymentPlanCd:
            this.paymentOption === 'Full'
              ? 'com.stillwater_DB01'
              : 'com.stillwater_CC70',
          methodPaymentCd: this.methodPaymentCd,
          installmentFeeAmt: {
            amt: this.paymentOption === 'Full' ? 0 : this.installmentAmount
          },
          electronicFundsTransfer: {
            fromAcct: {
              accountNumberId: this.formattedCCNumber,
              creditCardExpirationDt: this.ccExp
            },
            transferAmt: {
              amt:
                this.paymentOption === 'Full'
                  ? this.quote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
                    .PolicySummaryInfo[0].FullTermAmt[0].Amt[0]
                  : this.monthlyDeposit
            }
          },
          signatureInfo: {
            nameInfo: {
              personName: {
                surname: this.ccName[0],
                givenName: this.ccName[1]
              }
            }
          }
        }
      },
      formattedCCNumber () {
        if (this.ccNumber) {
          return this.ccNumber.replace(/ /g, '')
        } else {
          return null
        }
      },
      quote () {
        return this.$store.getters.getRentalQuote
      },
      yearlyPayment () {
        return numeral(
          this.quote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .PolicySummaryInfo[0].FullTermAmt[0].Amt[0]
        ).format('0.00')
      },
      monthlyPaymentPlan () {
        let options = this.quote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
          .PersPolicy[0].PaymentOption
        console.log('options', options)
        if (
          options.filter(
            option => option.PaymentPlanCd[0] === 'com.stillwater_CC70'
          ) !== undefined
        ) {
          return options.filter(
            option => option.PaymentPlanCd[0] === 'com.stillwater_CC70'
          )
        } else {
          return undefined
        }
      },
      monthlyDeposit () {
        let monthlyDeposit = ''
        if (this.monthlyPaymentPlan.length === 0) {
          return false
        } else {
          monthlyDeposit = this.monthlyPaymentPlan[0].DepositAmt[0].Amt[0]
        }
        return monthlyDeposit
      },
      installmentAmount () {
        return this.monthlyPaymentPlan[0].InstallmentInfo[0].InstallmentAmt[0]
          .Amt[0]
      },
      loading () {
        return this.$store.getters.getloading
      }
    },
    watch: {
      error (newValue) {
        console.log('error changed:', newValue)
        this.errorDialog = !!newValue
      }
    },
    created () {
      this.$store.dispatch('loadRentersDocuments')
      console.log('QUOTE', this.quote)
    },
    methods: {
      updateBillingAddress () {
        if (this.billingSameAsProperty) {
          const { Addr1, City, PostalCode, StateProvCd } = this.propertyAddress
          this.billingAddress = Addr1[0]
          this.billingCity = City[0]
          this.billingZip = PostalCode[0]
          this.billingState = StateProvCd[0]
        } else {
          this.billingAddress = ''
          this.billingCity = ''
          this.billingZip = ''
          this.billingState = ''
        }
      },
      clearError () {
        this.$store.dispatch('clearError')
        this.errorDialog = false
      },
      cardChanged (cardName) {
        console.log(cardName)
        switch (cardName) {
          case 'Visa':
            this.methodPaymentCd = 'VISA'
            break
          case 'Mastercard':
            this.methodPaymentCd = 'MASTR'
            break
          case 'Discover':
            this.methodPaymentCd = 'DISCV'
            break
          case 'Amex':
            this.methodPaymentCd = 'AMEX'
            break
          default:
            this.methodPaymentCd = ''
        }
      },
      handleCCData (values) {
        console.log(values)
        this.ccNumber = values.cardNumber
        console.log('formatted number', this.formattedCCNumber)
        if (values.expiration.length === 7) {
          this.ccExp = values.expiration
            .split('/')
            .reverse()
            .join('-')
          console.log(this.ccExp)
          if (moment(this.ccExp).isBefore(moment().format('YYYY-MM'))) {
            this.ccError = true
          } else {
            this.ccError = false
          }
        }
        this.ccName = values.name.split(/ (.*)/)
        this.ccCvv = values.security
        console.log(this.ccName)
      },
      getBillingAddressData (addressData, placeResultData, id) {
        this.billingAddress = addressData.name
        this.billingCity = addressData.locality
        this.billingZip = addressData.postal_code
        this.billingState = addressData.administrative_area_level_1
      },
      async next () {
        let regex = new RegExp('^[0-9]{3,4}$')
        if (!this.methodPaymentCd || !this.ccName[1]) {
          this.ccError = true
        } else if (!regex.test(this.ccCvv)) {
          this.ccError = true
        } else {
          let complete = await this.$refs.observer.validate()
          console.log(complete)
          if (!complete) {
            console.log('Error validating form')
          } else {
            this.$store
              .dispatch('rentersBindCC', {
                paymentOption: this.paymentData,
                addr: this.billAddr
              })
              .then(() => {
                if (!this.error) {
                  this.$router.push({ path: '/renters/policy' })
                }
              })
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.title-text {
  font-size: 36px;
  font-weight: 400;
  color: #00a1b7;
}

.sub-title {
  font-size: 20px;
  font-weight: 700;
  color: #00a1b7;
}

::v-deep .v-label {
  color: black;
}

::v-deep .v-input--selection-controls.v-input .v-label {
  font-weight: 500 !important;
  margin-bottom: 0 !important;
  color: black !important;
}
</style>
